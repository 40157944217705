import { IThunderboltEntry } from '@wix/editor-elements-types';
import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  Default: () => [
    import(
      './viewer/skinComps/Default/Default.skin' /* webpackChunkName: "StylableHorizontalMenu_Default" */
    ),
  ],
  WrapColumn: () => [
    import(
      './viewer/skinComps/WrapColumn/WrapColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_WrapColumn" */
    ),
  ],
  WrapFlyout: () => [
    import(
      './viewer/skinComps/WrapFlyout/WrapFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_WrapFlyout" */
    ),
  ],
  ScrollColumn: () => [
    import(
      './viewer/skinComps/ScrollColumn/ScrollColumn.skin' /* webpackChunkName: "StylableHorizontalMenu_ScrollColumn" */
    ),
  ],
  ScrollFlyout: () => [
    import(
      './viewer/skinComps/ScrollFlyout/ScrollFlyout.skin' /* webpackChunkName: "StylableHorizontalMenu_ScrollFlyout" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'StylableHorizontalMenu',
    UiTypes,
  ),
};

export default entry;
