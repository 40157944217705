import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  Classic: () => [
    import(
      './viewer/skinComps/Classic/Classic.skin' /* webpackChunkName: "CoBrandingBannerDesktop_Classic" */
    ),
  ],
  Responsive: () => [
    import(
      './viewer/skinComps/Responsive/Responsive.skin' /* webpackChunkName: "CoBrandingBannerDesktop_Responsive" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'CoBrandingBannerDesktop',
    UiTypes,
  ),
};

export default entry;
