import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/skinComps/BasicWPhoto/ClipArtSkin.skin' /* webpackChunkName: "WPhoto" */
    ),
    import('./viewer/WPhoto.controller' /* webpackChunkName: "WPhoto" */),
  ],
  ClipArtSkin: () => [
    import(
      './viewer/skinComps/BasicWPhoto/ClipArtSkin.skin' /* webpackChunkName: "WPhoto_ClipArtSkin" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_ClipArtSkin" */
    ),
  ],
  CirclePhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/CirclePhoto.skin' /* webpackChunkName: "WPhoto_CirclePhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_CirclePhoto" */
    ),
  ],
  DefaultPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/DefaultPhoto.skin' /* webpackChunkName: "WPhoto_DefaultPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_DefaultPhoto" */
    ),
  ],
  DoubleBorderCirclePhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/DoubleBorderCirclePhoto.skin' /* webpackChunkName: "WPhoto_DoubleBorderCirclePhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_DoubleBorderCirclePhoto" */
    ),
  ],
  DoubleBorderPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/DoubleBorderPhoto.skin' /* webpackChunkName: "WPhoto_DoubleBorderPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_DoubleBorderPhoto" */
    ),
  ],
  GlowLinePhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/GlowLinePhoto.skin' /* webpackChunkName: "WPhoto_GlowLinePhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_GlowLinePhoto" */
    ),
  ],
  IronPhoto: () => [
    import(
      './viewer/skinComps/IronPhoto/IronPhoto.skin' /* webpackChunkName: "WPhoto_IronPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_IronPhoto" */
    ),
  ],
  LiftedShadowPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/LiftedShadowPhoto.skin' /* webpackChunkName: "WPhoto_LiftedShadowPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_LiftedShadowPhoto" */
    ),
  ],
  LiftedTopPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/LiftedTopPhoto.skin' /* webpackChunkName: "WPhoto_LiftedTopPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_LiftedTopPhoto" */
    ),
  ],
  MouseOverPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/MouseOverPhoto.skin' /* webpackChunkName: "WPhoto_MouseOverPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_MouseOverPhoto" */
    ),
  ],
  NewPolaroidPhoto: () => [
    import(
      './viewer/skinComps/NewPolaroidPhoto/NewPolaroidPhoto.skin' /* webpackChunkName: "WPhoto_NewPolaroidPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_NewPolaroidPhoto" */
    ),
  ],
  NoSkinPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/NoSkinPhoto.skin' /* webpackChunkName: "WPhoto_NoSkinPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_NoSkinPhoto" */
    ),
  ],
  PaperclipPhoto: () => [
    import(
      './viewer/skinComps/PaperclipPhoto/PaperclipPhoto.skin' /* webpackChunkName: "WPhoto_PaperclipPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_PaperclipPhoto" */
    ),
  ],
  PolaroidPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/PolaroidPhoto.skin' /* webpackChunkName: "WPhoto_PolaroidPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_PolaroidPhoto" */
    ),
  ],
  RoundPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/RoundPhoto.skin' /* webpackChunkName: "WPhoto_RoundPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_RoundPhoto" */
    ),
  ],
  RoundShadowPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/RoundShadowPhoto.skin' /* webpackChunkName: "WPhoto_RoundShadowPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_RoundShadowPhoto" */
    ),
  ],
  ScotchDoubleHorizontal: () => [
    import(
      './viewer/skinComps/ScotchDouble/ScotchDoubleHorizontal.skin' /* webpackChunkName: "WPhoto_ScotchDoubleHorizontal" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchDoubleHorizontal" */
    ),
  ],
  ScotchDoubleVertical: () => [
    import(
      './viewer/skinComps/ScotchDouble/ScotchDoubleVertical.skin' /* webpackChunkName: "WPhoto_ScotchDoubleVertical" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchDoubleVertical" */
    ),
  ],
  ScotchTopPhoto: () => [
    import(
      './viewer/skinComps/BasicWPhoto/ScotchTopPhoto.skin' /* webpackChunkName: "WPhoto_ScotchTopPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchTopPhoto" */
    ),
  ],
  ScotchTapePhoto: () => [
    import(
      './viewer/skinComps/ScotchTapePhoto/ScotchTapePhoto.skin' /* webpackChunkName: "WPhoto_ScotchTapePhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_ScotchTapePhoto" */
    ),
  ],
  SloppyPhoto: () => [
    import(
      './viewer/skinComps/SloppyPhoto/SloppyPhoto.skin' /* webpackChunkName: "WPhoto_SloppyPhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_SloppyPhoto" */
    ),
  ],
  VintagePhoto: () => [
    import(
      './viewer/skinComps/VintagePhoto/VintagePhoto.skin' /* webpackChunkName: "WPhoto_VintagePhoto" */
    ),
    import(
      './viewer/WPhoto.controller' /* webpackChunkName: "WPhoto_VintagePhoto" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('WPhoto', UiTypes),
};

export default entry;
