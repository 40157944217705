import { IThunderboltEntry } from '@wix/editor-elements-types';
import {
  UiTypesRegistrationMap,
  registerComponentTypeUiTypes,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      // We want it to use the same chunk name as the main ComboBoxInput component
      './viewer/ComboBoxInputListModal' /* webpackChunkName: "ComboBoxInput" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes(
    'ComboBoxInputListModal',
    UiTypes,
  ),
};

export default entry;
